import { UxfGetServerSideProps, UxfGetStaticProps } from "@uxf/router";
import { PreviewData as NextPreviewData } from "next/types";
import router, { RouteList } from "./routes";

export const { route, routeToUrl, routes, useActiveRoute, useQueryParams } = router;

export type GetStaticProps<
    Route extends keyof RouteList,
    Props extends { [key: string]: any } = { [key: string]: any },
    PreviewData extends NextPreviewData = NextPreviewData,
> = UxfGetStaticProps<RouteList, Route, Props, PreviewData>;

export type GetServerSideProps<
    Route extends keyof RouteList,
    Props extends { [key: string]: any } = { [key: string]: any },
    PreviewData extends NextPreviewData = NextPreviewData,
> = UxfGetServerSideProps<RouteList, Route, Props, PreviewData>;
