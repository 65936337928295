import { routeToUrl } from "@app-routes";
import { NextPageContext } from "next";
import Router from "next/router";

const redirect: (ctx: NextPageContext, ...attrs: any) => Promise<any> = async (
    ctx: NextPageContext,
    route,
    params = undefined,
): Promise<any> => {
    if (typeof window === "undefined") {
        if (ctx.res && !ctx.res.headersSent) {
            ctx.res.writeHead(302, { Location: routeToUrl(route as any, params as any) });
            ctx.res.end();
        }
    } else {
        await Router.push(routeToUrl(route as any, params as any));
    }
    return {};
};

const redirectToUrl = async (ctx: NextPageContext, url: string): Promise<any> => {
    if (typeof window === "undefined") {
        if (ctx.res && !ctx.res.headersSent) {
            ctx.res.writeHead(302, { Location: url });
            ctx.res.end();
        }
    } else {
        window.location.href = url;
    }
    return {};
};

export const RedirectHelper = {
    redirect,
    redirectToUrl,
};
