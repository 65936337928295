import { translations } from "@translations/translations";
import { PluralResolverMap, TranslationService } from "@uxf/translations";

export enum SupportedLanguages {
    Cs = "cs",
    En = "en",
}

export type Translations = typeof translations;
export type TranslationStrings = keyof Translations;

/*
 * 0.2  many
 * 1    one
 * 2    few
 * 3    few
 * 4    other
 * 5    other
 * 6    other
 * 10.8 many
 * 100  other
 */

const pluralResolverMap: PluralResolverMap = {
    [SupportedLanguages.Cs]: (count) =>
        count % 1 !== 0 ? "many" : count === 1 ? "one" : count >= 2 && count <= 4 ? "few" : "other",
    [SupportedLanguages.En]: (count) => (count === 1 ? "one" : "other"),
};

export const translationService = new TranslationService<TranslationStrings, SupportedLanguages>(
    translations,
    SupportedLanguages.Cs,
    pluralResolverMap,
);

export const t = translationService.translate;
