/* eslint-disable */
/* tslint:disable */
/* prettier-ignore */
export const translations = {
    "component.meals": {
        "cs": {
            "zero": "jídla",
            "one": "jídlo",
            "few": "jídla",
            "other": "jídel"
        }
    },
    "component.dataTable.action": {
        "cs": "Akce",
        "en": "Action"
    },
    "component.dataTable.choose": {
        "cs": "Vyberte...",
        "en": "Choose..."
    },
    "component.dataTable.confirmDelete": {
        "cs": "Opravdu chcete smazat položku?",
        "en": "Are you sure to delete item?"
    },
    "component.dataTable.countedItem": {
        "cs": "{{count}} záznamů",
        "en": "{{count}} item"
    },
    "component.dataTable.countedItem_plural": {
        "cs": "{{count}} záznamů",
        "en": "{{count}} items"
    },
    "component.dataTable.delete": {
        "cs": "Smazat",
        "en": "Delete"
    },
    "component.dataTable.detail": {
        "cs": "Detail",
        "en": "Detail"
    },
    "component.dataTable.duplicate": {
        "cs": "Duplikovat",
        "en": "Duplicate"
    },
    "component.dataTable.edit": {
        "cs": "Upravit",
        "en": "Edit"
    },
    "component.dataTable.exportCsv": {
        "cs": "Exportovat CSV",
        "en": "Export SVG"
    },
    "component.dataTable.exportPdf": {
        "cs": "Exportovat PDF",
        "en": "Export PDF"
    },
    "component.dataTable.filterColumns": {
        "cs": "Filtrovat sloupce",
        "en": "Filter columns"
    },
    "component.dataTable.filtered": {
        "cs": "Vyfiltrováno",
        "en": "Filtered"
    },
    "component.dataTable.hide": {
        "cs": "skrýt",
        "en": "hide"
    },
    "component.dataTable.itemNotDeleted": {
        "cs": "Záznam se nepodařilo smazat.",
        "en": "Item could not be deleted."
    },
    "component.dataTable.itemsPerPage": {
        "cs": "Počet záznamů na stránce:",
        "en": "Items per page:"
    },
    "component.dataTable.loading": {
        "cs": "nahrávání",
        "en": "loading"
    },
    "component.dataTable.newItem": {
        "cs": "Přidat záznam",
        "en": "New item"
    },
    "component.dataTable.next": {
        "cs": "Následující",
        "en": "Next"
    },
    "component.dataTable.no": {
        "cs": "Ne",
        "en": "No"
    },
    "component.dataTable.noItemsFound": {
        "cs": "Žádné záznamy nenalezeny.",
        "en": "No items found."
    },
    "component.dataTable.previous": {
        "cs": "Předchozí",
        "en": "Previous"
    },
    "component.dataTable.search": {
        "cs": "Hledat",
        "en": "Search"
    },
    "component.dataTable.show": {
        "cs": "zobrazit",
        "en": "show"
    },
    "component.dataTable.total": {
        "cs": "Celkem",
        "en": "Total"
    },
    "component.dataTable.yes": {
        "cs": "Ano",
        "en": "Yes"
    },
    "component.formField.dateAria": {
        "cs": "změnit datum",
        "en": "change date"
    },
    "component.formField.dateCancelLabel": {
        "cs": "Zrušit",
        "en": "Cancel"
    },
    "component.formField.dateInvalidMessage": {
        "cs": "Vyplňte platné datum",
        "en": "Fill valid date"
    },
    "component.formField.dateTimeAria": {
        "cs": "změnit datum a čas",
        "en": "change date and time"
    },
    "component.formField.dateTodayLabel": {
        "cs": "Dnes",
        "en": "Today"
    },
    "component.formField.emptyLabel": {
        "cs": "Nevyplněno",
        "en": "Unfilled"
    },
    "component.formField.multiSelectPlaceholder": {
        "cs": "Vyberte možnosti...",
        "en": "Choose options..."
    },
    "component.formField.selectPlaceholder": {
        "cs": "Vyberte možnost...",
        "en": "Choose option..."
    },
    "component.formField.selectsNoOptions": {
        "cs": "Nic nenalezeno...",
        "en": "No options..."
    },
    "component.formField.TimeAria": {
        "cs": "změnit čas",
        "en": "change time"
    },
    "component.layout.breadcrumbs": {
        "cs": "Drobečková navigace",
        "en": "Breadcrumbs"
    },
    "component.layout.footer.allRightsReserved": {
        "cs": "Všechna práva vyhrazena",
        "en": "All rights reserved"
    },
    "component.layout.footer.limited": {
        "cs": "s.r.o.",
        "en": "Ltd."
    },
    "component.layout.hideSidebar": {
        "cs": "Skrýt boční lištu",
        "en": "Hide sidebar"
    },
    "component.layout.loggedUser": {
        "cs": "Přihlášený uživatel",
        "en": "Logged user"
    },
    "component.layout.login": {
        "cs": "Přihlásit se",
        "en": "Login"
    },
    "component.layout.logout": {
        "cs": "Odhlásit se",
        "en": "Logout"
    },
    "component.layout.mainMenu": {
        "cs": "Hlavní menu",
        "en": "Main menu"
    },
    "component.layout.pageMainAction": {
        "cs": "Hlavní akce na stránce",
        "en": "Main action on page"
    },
    "component.layout.pageMenu": {
        "cs": "Další akce",
        "en": "Další akce"
    },
    "component.layout.pageTools": {
        "cs": "Nástroje stránky",
        "en": "Page tools"
    },
    "component.layout.showLoggedUserOptions": {
        "cs": "Zobrazit možnosti přihlášeného uživatele",
        "en": "Show logged user options"
    },
    "component.layout.showSidebar": {
        "cs": "Zobrazit boční lištu",
        "en": "Show sidebar"
    },
    "component.layout.showUserProfile": {
        "cs": "Zobrazit profil",
        "en": "Show profile"
    },
    "component.pageLoader.pleaseWait": {
        "cs": "Vyčkejte prosím...",
        "en": "Please wait..."
    },
    "component.table.detail": {
        "cs": "Detail",
        "en": "Detail"
    },
    "component.table.duplicate": {
        "cs": "Duplikovat",
        "en": "Duplicate"
    },
    "component.table.edit": {
        "cs": "Editovat",
        "en": "Edit"
    },
    "component.table.firstPage": {
        "cs": "První stránka",
        "en": "First Page"
    },
    "component.table.lastPage": {
        "cs": "Poslední stránka",
        "en": "Last Page"
    },
    "component.table.more": {
        "cs": "Více",
        "en": "More"
    },
    "component.table.nextPage": {
        "cs": "Další stránka",
        "en": "Next Page"
    },
    "component.table.previousPage": {
        "cs": "Předchozí stránka",
        "en": "Previous Page"
    },
    "component.table.remove": {
        "cs": "Smazat",
        "en": "Remove"
    },
    "component.table.search": {
        "cs": "Hledat",
        "en": "Search"
    },
    "component.table.searchByColumn": {
        "cs": "Hledat podle sloupce",
        "en": "Search by column"
    },
    "component.table.select": {
        "cs": "Vyberte...",
        "en": "Select..."
    },
    "component.table.sort": {
        "cs": "Seřadit",
        "en": "Sort"
    },
    "error.errorOccurred": {
        "cs": "Vyskytla se chyba",
        "en": "An error has occurred"
    },
    "error.failedToSaveData": {
        "cs": "Data se nepodařilo uložit.",
        "en": "Failed to save data."
    },
    "error.invalidEmail": {
        "cs": "Emailová adresa není platná.",
        "en": "Email address is not valid."
    },
    "error.networkError": {
        "cs": "Server je nedostupný nebo nejste připojeni k internetu.",
        "en": "The server is unavailable or you are not connected to the Internet."
    },
    "error.noPermission": {
        "cs": "Na tuto operaci nemáte oprávnění.",
        "en": "You do not have permission to perform this operation."
    },
    "error.somethingWentWrong": {
        "cs": "Něco se nepovedlo.",
        "en": "Something went wrong."
    },
    "error.unknownError": {
        "cs": "Neznámá chyba!",
        "en": "Unknown error!"
    },
    "error.wrongNameOrPassword": {
        "cs": "Chybné jméno nebo heslo.",
        "en": "Wrong name or password."
    },
    "error.youMustSignIn": {
        "cs": "Musíte se přihlásit.",
        "en": "You must sign in."
    },
    "form.common.cancel": {
        "cs": "Zrušit",
        "en": "Cancel"
    },
    "form.common.email": {
        "cs": "Email",
        "en": "Email"
    },
    "form.common.end": {
        "cs": "Konec",
        "en": "End"
    },
    "form.common.invalidEmail": {
        "cs": "E-mail není platný.",
        "en": "E-mail is not valid."
    },
    "form.common.name": {
        "cs": "Jméno",
        "en": "Name"
    },
    "form.common.passwordRequired": {
        "cs": "Vyplňte heslo prosím.",
        "en": "Please fill in the password."
    },
    "form.common.passwordTooShort": {
        "cs": "Heslo musí být delší než 5 znaků.",
        "en": "The password must be longer than 5 characters."
    },
    "form.common.passwordsDoNotMatch": {
        "cs": "Hesla se neshodují.",
        "en": "Passwords do not match."
    },
    "form.common.requiredField": {
        "cs": "Povinné pole.",
        "en": "Field is required."
    },
    "form.common.roles": {
        "cs": "Role",
        "en": "Roles"
    },
    "form.common.save": {
        "cs": "Uložit",
        "en": "Save"
    },
    "form.common.start": {
        "cs": "Začátek",
        "en": "Start"
    },
    "form.common.userInvitationButton": {
        "cs": "Vytvořit uživatele a poslat mu pozvánku",
        "en": "Create a user and send him/her an invitation"
    },
    "form.common.userInvitationDescription": {
        "cs": "Nový uživatel obdrží e-mail s odkazem na stránku, kde potvrdí vytvoření nového účtu a nastaví si heslo ke svému účtu.",
        "en": "The new user will receive an email with a link to the page, where they will confirm the creation of a new account and set their account password."
    },
    "form.common.userInvitationLabel": {
        "cs": "Pozvat nového uživatele",
        "en": "Invite new user"
    },
    "form.forgottenPassword.email": {
        "cs": "Email",
        "en": "Email"
    },
    "form.forgottenPassword.emailSent": {
        "cs": "Email byl odeslán.",
        "en": "Email has been sent."
    },
    "form.forgottenPassword.forgottenPassword": {
        "cs": "Zapomenuté heslo",
        "en": "Forgotten password"
    },
    "form.forgottenPassword.login": {
        "cs": "Přihlášení",
        "en": "Login"
    },
    "form.forgottenPassword.resetPassword": {
        "cs": "Obnovit heslo",
        "en": "Reset password"
    },
    "form.login.email": {
        "cs": "E-mail",
        "en": "E-mail"
    },
    "form.login.forgottenPasswordButton": {
        "cs": "Zapomenuté heslo",
        "en": "Forgotten password"
    },
    "form.login.password": {
        "cs": "Heslo",
        "en": "Password"
    },
    "form.login.submitButton": {
        "cs": "Přihlásit",
        "en": "Log in"
    },
    "form.renewPassword.changePassword": {
        "cs": "Změnit heslo",
        "en": "Change password"
    },
    "form.renewPassword.changePasswordSuccessMessage": {
        "cs": "Heslo bylo změněno.",
        "en": "Password was changed."
    },
    "form.renewPassword.newPassword": {
        "cs": "Nové heslo",
        "en": "New password"
    },
    "form.renewPassword.newPasswordAgain": {
        "cs": "Zopakovat nové heslo",
        "en": "Retype new password"
    },
    "form.renewPassword.resetPasswordAgain": {
        "cs": "Vyskytla se chyba. Zkuste prosím obnovit heslo znovu.",
        "en": "An error has occurred. Try to reset password again."
    },
    "form.renewPassword.tokenExpired": {
        "cs": "Token pro obnovení hesla vypršel",
        "en": "Password reset token expired"
    },
    "form.renewPassword.tokenNotFound": {
        "cs": "Token nebyl nalezen!",
        "en": "Token not found"
    },
    "form.tableForm.editItem": {
        "cs": "Editace",
        "en": "Editing"
    },
    "form.tableForm.newItem": {
        "cs": "Nový záznam",
        "en": "New item"
    },
    "form.userProfile.myProfile": {
        "cs": "Můj profil",
        "en": "My profile"
    },
    "form.userProfile.originalPassword": {
        "cs": "Původní heslo",
        "en": "Original password"
    },
    "form.userProfile.passwordTooShort": {
        "cs": "Heslo musí být dlouhé alespoň 4 znaky",
        "en": "Password must be at least 4 characters long"
    },
    "form.userProfile.passwordsDoNotMatch": {
        "cs": "Hesla se neshodují",
        "en": "Passwords do not match"
    },
    "global.no": {
        "cs": "Ne",
        "en": "No"
    },
    "global.yes": {
        "cs": "Ano",
        "en": "Yes"
    },
    "page.admin.administration": {
        "cs": "Administrace",
        "en": "Administration"
    },
    "page.admin.invitationSend": {
        "cs": "Pozvánka odeslána novému uživateli.",
        "en": "Invitation sent to new user."
    },
    "page.files.clickOrDragFiles": {
        "cs": "Přetáhněte soubor nebo klikněte pro nahrání",
        "en": "Drag files or click to upload"
    },
    "page.files.copyUrl": {
        "cs": "Kopírovat URL",
        "en": "Copy URL"
    },
    "page.files.size": {
        "cs": "Velikost",
        "en": "Size"
    },
    "page.files.uploadFile": {
        "cs": "Nahrát soubor",
        "en": "Upload file"
    },
    "page.files.uploaded": {
        "cs": "Nahráno",
        "en": "Uploaded"
    },
    "page.files.uploadedFiles": {
        "cs": "Nahrané soubory",
        "en": "Uploaded Files"
    },
    "page.index.welcome": {
        "cs": "Vítejte",
        "en": "Welcome"
    },
    "page.index.welcomeAtCms": {
        "cs": "Vítejte v CMS",
        "en": "Welcome at CMS"
    },
    "page.login.invalidCredentials": {
        "cs": "Špatné jméno nebo heslo.",
        "en": "Invalid credentials"
    },
    "page.login.title": {
        "cs": "Přihlášení",
        "en": "Login"
    },
    "page.newTable.deleteConfirm": {
        "cs": "Opravdu chcete smazat položku?",
        "en": "Are you sure you want to delete the item?"
    },
    "Invalid password": {
        "cs": "Nesprávné heslo.",
        "en": "Invalid password"
    },
    "User not found": {
        "cs": "Neznámý uživatelský e-mail.",
        "en": "Invalid user e-mail"
    }
};
