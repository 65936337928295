import { createRouter } from "@uxf/router";
import { CmsRouteList, cmsRoutes } from "./cmsRoutes";

export interface RouteList extends CmsRouteList {
    index: null;

    // super zone
    "super-zone/restaurant-list": null;
    "super-zone/restaurant-create-update": { id?: number };
    "super-zone/payments-and-documents": null;

    "super-zone/allergen-list": null;
    "super-zone/allergen-create-update": { id?: number };

    "super-zone/meal-type-list": null;
    "super-zone/meal-type-create-update": { id?: number };

    "super-zone/restaurant-tag-list": null;
    "super-zone/restaurant-tag-create-update": { id?: number };

    // restaurant zone
    "restaurant-zone/daily-menus": { date?: string };
    "restaurant-zone/daily-menus-print": { date: string; generate_pdf: true };
    "restaurant-zone/meal-list": null;
    "restaurant-zone/meal-create-update": { id?: number };
    "restaurant-zone/overviews": null;
    "restaurant-zone/overview-detail": { date: string; restaurant?: number };
    "restaurant-zone/overview-detail-print": { date: string; restaurant?: number; generate_pdf: true };
    "restaurant-zone/voucher-scan": null;
    "restaurant-zone/restaurant-settings": null;
    "restaurant-zone/changes-archive": null;
    "restaurant-zone/employee-create-update": { id?: number };
    "restaurant-zone/last-delivered": null;

    // user zone
    "user-zone/user-invite": null;
    "user-zone/change-password": null;
    "user-zone/profile-settings": null;
}

export default createRouter<RouteList>({
    index: "/",
    // cms routes
    ...cmsRoutes,

    // super zone
    "super-zone/restaurant-list": "/super/prehled-restauraci",
    "super-zone/restaurant-create-update": "/super/formular-restaurace",

    "super-zone/allergen-list": "/super/prehled-alergenu",
    "super-zone/allergen-create-update": "/super/formular-alergen",
    "super-zone/meal-type-list": "/super/prehled-typu-jidel",
    "super-zone/meal-type-create-update": "/super/formular-typ-jidla",
    "super-zone/restaurant-tag-list": "/super/prehled-tagu-restauraci",
    "super-zone/restaurant-tag-create-update": "/super/formular-tag-restaurace",
    "super-zone/payments-and-documents": "/super/platby-a-dokumenty",

    // restaurant zone
    "restaurant-zone/daily-menus": "/restaurace/jidelni-listky",
    "restaurant-zone/daily-menus-print": "/restaurace/jidelni-listky-tisk",
    "restaurant-zone/meal-list": "/restaurace/seznam-jidel",
    "restaurant-zone/meal-create-update": "/restaurace/formular-pokrm",
    "restaurant-zone/overviews": "/restaurace/prehledy",
    "restaurant-zone/overview-detail": "/restaurace/prehledy/[date]",
    "restaurant-zone/overview-detail-print": "/restaurace/prehledy/tisk",
    "restaurant-zone/voucher-scan": "/restaurace/skenovani-kuponu",
    "restaurant-zone/restaurant-settings": "/restaurace/nastaveni-restaurace",
    "restaurant-zone/changes-archive": "/restaurace/archiv-zmen",
    "restaurant-zone/employee-create-update": "/restaurace/formular-zamestnanec",
    "restaurant-zone/last-delivered": "/restaurace/naposledy-vydane",

    // user zone
    "user-zone/user-invite": "/user/invite",
    "user-zone/change-password": "/user/change-password",
    "user-zone/profile-settings": "/user/profile",
});
