import { config as cmsConfig, container } from "@uxf/cms/config";
/* */
import { route, useActiveRoute } from "@app-routes";
import { config } from "@config/config";
import { ICONS } from "@config/icons";
import { ICONS_VERSION, ICON_SPRITE } from "@config/icons-config";
import { twScreens } from "@config/tw-tokens/tw-screens";
import { Barlow, Barlow_Condensed } from "@next/font/google";
import * as Sentry from "@sentry/browser";
import {
    ProfileResponse,
    RestaurantMyRestaurantResponse,
    apiProfileZoneGetProfileMeGet,
    apiRestaurantZoneGetMyRestaurantsGet,
} from "@shared/api/generated";
import { AppContextProvider, RESTAURANT_ID_COOKIE_NAME, RESTAURANT_ID_COOKIE_TTL } from "@shared/contexts/app-context";
import { ErrorService } from "@shared/services/error-service";
import { RedirectHelper } from "@shared/utils/RedirectHelper";
import "@ui/styles/globals.css";
import { FileResponse } from "@uxf/cms/api";
import { IReduxState, useSelector, withReduxStore } from "@uxf/cms/deprecated/redux";
import { getLoggedUser } from "@uxf/cms/deprecated/redux/user";
import { ForbiddenError } from "@uxf/cms/errors/ForbiddenError";
import { UnauthorizedError } from "@uxf/cms/errors/UnauthorizedError";
import { UiContextProvider as UiCmsContextProvider } from "@uxf/cms/ui";
import { Cookie } from "@uxf/core/cookie";
import { UiContextProvider, UiContextType } from "@uxf/ui/context";
import { FlashMessages } from "@uxf/ui/flash-messages/flash-messages";
import { getFlashMessagesRef } from "@uxf/ui/flash-messages/flash-messages-service";
import { Modal } from "@uxf/ui/modal/modal";
import { getModalRef } from "@uxf/ui/modal/modal-service";
import axios from "axios";
import { locale } from "dayjs";
import "dayjs/locale/cs";
import "focus-visible";
import App, { AppProps } from "next/app";
import Head from "next/head";
import Router from "next/router";
import Nprogress from "nprogress";
import process from "process";
import { Store } from "redux";

locale("cs");

interface Props extends AppProps {
    reduxStore: Store<IReduxState>;
}

if (process.env.SENTRY_DSN) {
    Sentry.init({
        dsn: process.env.SENTRY_DSN,
        environment: process.env.NODE_ENV,
    });
}

cmsConfig.set("api-url", process.env.NEXT_PUBLIC_FRONTEND_URL ?? "").set("app-name", "Předplátko.cz");

container
    .register("api.uploadFile", async (file, namespace) => {
        // TODO move to cms?
        const formData = new FormData();
        formData.append("files[]", file);

        const response = await axios.post<FileResponse[]>("/api/cms/files/upload", formData, {
            timeout: 100 * 1000,
            params: { namespace: namespace ?? "default" },
        });

        if (response.data.length > 0) {
            return response.data[0];
        }

        throw new Error("Response is null");
    })
    .register("service.error", ErrorService)
    .register("route", route as any)
    .register("useActiveRoute", useActiveRoute)
    .register("useLoggedUser", () => useSelector(getLoggedUser) ?? { roles: [] }); // TODO @vejvis

const UI_PROVIDER_CONFIG: UiContextType = {
    icon: {
        spriteFilePath: `${ICON_SPRITE}?v=${ICONS_VERSION}`,
        iconsConfig: ICONS,
    },
    rasterImage: {
        breakpoints: twScreens,
    },
    colorScheme: {
        defaultColorScheme: "light",
        getUserSettings: () => "light",
        respectSystemSetting: false,
        respectUserSettings: false,
        setUserSettings: () => void null,
    },
    domain: config.FRONTEND_URL,
};

const fontBarlow = Barlow({
    adjustFontFallback: true,
    preload: true,
    subsets: ["latin", "latin-ext"],
    variable: "--font-barlow",
    weight: ["400", "500", "600"],
    display: "swap",
});

const fontBarlowCondensed = Barlow_Condensed({
    adjustFontFallback: true,
    preload: true,
    subsets: ["latin", "latin-ext"],
    variable: "--font-barlow-condensed",
    weight: ["600"],
    display: "swap",
});

class UxfApp extends App<Props> {
    private onRouteChangeStart = (_: any, { shallow }: any) => !shallow && Nprogress.start();
    private onRouteChangeStop = (_: any, { shallow }: any) => !shallow && Nprogress.done();

    public componentDidMount() {
        Nprogress.configure({ showSpinner: false });
        Router.events.on("routeChangeStart", this.onRouteChangeStart);
        Router.events.on("routeChangeComplete", this.onRouteChangeStop);
        Router.events.on("routeChangeError", this.onRouteChangeStop);
    }

    public componentWillUnmount(): void {
        Router.events.off("routeChangeStart", this.onRouteChangeStart);
        Router.events.off("routeChangeComplete", this.onRouteChangeStop);
        Router.events.off("routeChangeError", this.onRouteChangeStop);
    }

    public render() {
        const { Component, pageProps } = this.props;

        return (
            <>
                {/* eslint-disable-next-line react/no-unknown-property */}
                <style jsx global>
                    {`
                        :root {
                            --font-barlow: ${fontBarlow.style.fontFamily};
                            --font-barlow-condensed: ${fontBarlowCondensed.style.fontFamily};
                        }
                    `}
                </style>
                <Head>
                    <meta
                        name="viewport"
                        content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no"
                    />
                </Head>
                {/*TODO: @kubaH - hack se zdvojeným providerem*/}
                <UiContextProvider value={UI_PROVIDER_CONFIG}>
                    <UiCmsContextProvider value={UI_PROVIDER_CONFIG}>
                        <AppContextProvider
                            state={{
                                restaurantId: pageProps.restaurantId,
                                restaurantRole: null,
                                profile: pageProps.profile,
                                myRestaurants: pageProps.myRestaurants,
                            }}
                        >
                            <Component {...pageProps} />
                            <FlashMessages ref={getFlashMessagesRef()} />
                            <Modal ref={getModalRef()} />
                        </AppContextProvider>
                    </UiCmsContextProvider>
                </UiContextProvider>
            </>
        );
    }

    public static async getInitialProps(appContext) {
        try {
            const appProps = await App.getInitialProps(appContext);

            let restaurantId = parseInt(Cookie.create(appContext.ctx).get(RESTAURANT_ID_COOKIE_NAME), 10) || null;

            const authCookie = Cookie.create(appContext.ctx).get("X-Authorization");

            let profile: ProfileResponse | null = null,
                myRestaurants: RestaurantMyRestaurantResponse[] | null = null;
            if (authCookie) {
                myRestaurants = (await apiRestaurantZoneGetMyRestaurantsGet(appContext.ctx)).data;
                profile = (await apiProfileZoneGetProfileMeGet(appContext.ctx)).data;
                if (!myRestaurants.find((r) => r.id === restaurantId)) {
                    restaurantId = null;
                }
                if (restaurantId === null) {
                    restaurantId = myRestaurants[0]?.id ?? null;
                    if (restaurantId) {
                        Cookie.create(appContext.ctx).set(
                            RESTAURANT_ID_COOKIE_NAME,
                            restaurantId.toString(),
                            RESTAURANT_ID_COOKIE_TTL,
                        );
                    } else {
                        throw new ForbiddenError();
                    }
                }
            }

            return {
                ...appProps,
                pageProps: { ...appProps.pageProps, restaurantId, myRestaurants, profile },
            } as any;
        } catch (e) {
            if (e instanceof UnauthorizedError || e instanceof ForbiddenError) {
                Cookie.create(appContext.ctx).delete("X-Authorization");

                return await RedirectHelper.redirect(appContext.ctx, "cms/login", {
                    redirect: appContext.ctx.asPath,
                });
            }

            throw e;
        }
    }
}

export default withReduxStore(UxfApp);
