import { FormPageQueryParams } from "@uxf/cms/pages/form-page";
import { LoginPageQueryParams } from "@uxf/cms/pages/login-page";

export interface CmsRouteList {
    "cms/index": null;
    "cms/table-form": FormPageQueryParams;
    "cms/table": { entityAlias: string };
    "cms/user-profile": null;
    "cms/admin": null;
    "cms/login": LoginPageQueryParams;
    "cms/logout": null;
    "cms/forgotten-password": null;
    "cms/renew-password": { token: string };
    "cms/user-list": null;
}

export const cmsRoutes: { [key in keyof CmsRouteList]: string } = {
    "cms/index": "/",
    "cms/table-form": "/table/[entityAlias]/form",
    "cms/table": "/table/[entityAlias]",
    "cms/user-profile": "/user/profile",
    "cms/admin": "/admin",
    "cms/login": "/login",
    "cms/logout": "/logout",
    "cms/forgotten-password": "/forgotten-password",
    "cms/renew-password": "/renew-password/[token]",
    "cms/user-list": "/users",
};
