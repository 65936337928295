import { ProfileResponse, RestaurantMyRestaurantResponse, RestaurantProfileRole } from "@shared/api/generated";
import { Cookie } from "@uxf/core/cookie";
import produce from "immer";
import { Dispatch, FC, ReactNode, Reducer, createContext, useContext, useReducer } from "react";

export type TypedReducerActions<Actions> = {
    [K in keyof Actions]: Actions[K] extends never
        ? {
              type: K;
          }
        : {
              type: K;
              payload: Actions[K];
          };
}[keyof Actions];

type State = {
    restaurantId: number | null;
    profile: ProfileResponse | null;
    restaurantRole: RestaurantProfileRole | null;
    myRestaurants: RestaurantMyRestaurantResponse[] | null;
};

type Callbacks = {
    setRestaurantId: number;
    setProfile: ProfileResponse | null;
    setRestaurantRole: RestaurantProfileRole | null;
    setMyRestaurants: RestaurantMyRestaurantResponse[] | null;
};

type Actions = TypedReducerActions<Callbacks>;

export const appContextReducer: Reducer<State, Actions> = (state, action): State => {
    switch (action.type) {
        case "setRestaurantId":
            return produce(state, (draft) => {
                draft.restaurantId = action.payload;
            });
        case "setProfile":
            return produce(state, (draft) => {
                draft.profile = action.payload;
            });
        case "setRestaurantRole":
            return produce(state, (draft) => {
                draft.restaurantRole = action.payload;
            });
        case "setMyRestaurants":
            return produce(state, (draft) => {
                draft.myRestaurants = action.payload;
            });
        default:
            return state;
    }
};

export const AppContext = createContext<{
    state?: State;
    dispatch?: Dispatch<Actions>;
}>({});

interface Props {
    children: ReactNode;
    state: State;
}

export const RESTAURANT_ID_COOKIE_NAME = "restaurantId";
export const RESTAURANT_ID_COOKIE_TTL = 3600 * 24 * 365; // 1 year

export const getCurrentRestaurantIdFromCookie = () => parseInt(Cookie.create(null).get(RESTAURANT_ID_COOKIE_NAME), 10);

export const AppContextProvider: FC<Props> = (props) => {
    const [state, dispatch] = useReducer(appContextReducer, {
        restaurantId: props.state.restaurantId,
        profile: props.state.profile,
        restaurantRole: props.state.restaurantRole,
        myRestaurants: props.state.myRestaurants,
    });
    const value = {
        state,
        dispatch,
    };

    return <AppContext.Provider value={value}>{props.children}</AppContext.Provider>;
};

export const useAppContext = () => useContext(AppContext);

export const useRestaurantId = (): number => {
    const context = useContext(AppContext);

    if (!context.state?.restaurantId) {
        throw new Error("Unknown restaurant");
    }

    return context.state.restaurantId;
};

export const useRestaurant = (): RestaurantMyRestaurantResponse => {
    const context = useContext(AppContext);

    const restaurant = context.state?.myRestaurants?.find((r) => r.id === context.state?.restaurantId);

    if (!context.state?.restaurantId || !restaurant) {
        throw new Error("Unknown restaurant");
    }

    return restaurant;
};

export const useNullableRestaurantId = (): number | null => {
    const context = useContext(AppContext);

    return context.state?.restaurantId || null;
};

export const useSetRestaurantId = () => {
    const context = useContext(AppContext);

    return (newRestaurantId: number) => {
        context.dispatch?.({ type: "setRestaurantId", payload: newRestaurantId });
        Cookie.create(null).set(RESTAURANT_ID_COOKIE_NAME, newRestaurantId.toString(), RESTAURANT_ID_COOKIE_TTL);
    };
};

export const useIsAdminInCurrentRestaurant = () => {
    const context = useContext(AppContext);

    return context.state?.restaurantRole === RestaurantProfileRole.Admin;
};
