// this file is generated automatically, do not change anything manually in the contents of this file

export const ICONS = {
    "arrow-left": { w: 448, h: 512 },
    "arrow-right": { w: 448, h: 512 },
    "calendar": { w: 448, h: 512 },
    "camera": { w: 512, h: 512 },
    "caretDown": { w: 320, h: 512 },
    "clock": { w: 512, h: 512 },
    "bars": { w: 448, h: 512 },
    "check": { w: 512, h: 512 },
    "chevronDown": { w: 512, h: 512 },
    "chevronLeft": { w: 384, h: 512 },
    "chevronsLeft": { w: 512, h: 512 },
    "chevronRight": { w: 384, h: 512 },
    "chevronsRight": { w: 512, h: 512 },
    "chevronUp": { w: 512, h: 512 },
    "cloud": { w: 640, h: 512 },
    "copy": { w: 512, h: 512 },
    "ellipsis-vertical": { w: 128, h: 512 },
    "file": { w: 384, h: 512 },
    "imageFile": { w: 384, h: 512 },
    "triangle-exclamation": { w: 512, h: 512 },
    "user": { w: 448, h: 512 },
    "videoFile": { w: 384, h: 512 },
    "xmark": { w: 320, h: 512 },
    "xmarkLarge": { w: 448, h: 512 },
    "circle-info": { w: 512, h: 512 },
    "filter": { w: 512, h: 512 },
    "pen": { w: 512, h: 512 },
    "table": { w: 512, h: 512 },
    "file-arrow-down": { w: 384, h: 512 },
    "table-columns": { w: 640, h: 512 },
    "search": { w: 512, h: 512 },
    "file-download": { w: 384, h: 512 },
    "trash": { w: 448, h: 512 },
    "warning": { w: 512, h: 512 },
    "h1": { w: 576, h: 512 },
    "h2": { w: 640, h: 512 },
    "h3": { w: 640, h: 512 },
    "h4": { w: 640, h: 512 },
    "h5": { w: 640, h: 512 },
    "h6": { w: 640, h: 512 },
    "bold": { w: 384, h: 512 },
    "italic": { w: 384, h: 512 },
    "underline": { w: 448, h: 512 },
    "ol": { w: 512, h: 512 },
    "ul": { w: 576, h: 512 },
    "code-simple": { w: 576, h: 512 },
    "link": { w: 640, h: 512 },
    "image": { w: 512, h: 512 },
    "youtube": { w: 576, h: 512 },
    "block-quote": { w: 576, h: 512 },
    "arrow-turn-down-left": { w: 512, h: 512 },
    "arrow-turn-down-right": { w: 512, h: 512 },
    "rectangle-wide": { w: 640, h: 512 },
    "edit": { w: 512, h: 512 },
    "delete": { w: 448, h: 512 },
    "open-link-in-new-tab": { w: 512, h: 512 },
    "highlighter-line": { w: 576, h: 512 },
    "angleRight": { w: 256, h: 512 },
    "arrow-down-from-line": { w: 384, h: 512 },
    "arrow-up-to-line": { w: 384, h: 512 },
    "grip-dots-vertical": { w: 256, h: 512 },
    "key": { w: 512, h: 512 },
    "plus": { w: 448, h: 512 },
    "right-from-bracket": { w: 512, h: 512 },
    "archive": { w: 512, h: 512 },
    "book": { w: 448, h: 512 },
    "gear": { w: 512, h: 512 },
    "list-check": { w: 512, h: 512 },
    "money-bill": { w: 576, h: 512 },
    "money-check-dollar-pen": { w: 640, h: 512 },
    "plate-utensils": { w: 640, h: 512 },
    "qrcode": { w: 448, h: 512 },
    "rotate-right": { w: 512, h: 512 },
    "sack-dollar": { w: 512, h: 512 },
    "shop": { w: 640, h: 512 },
    "timer": { w: 512, h: 512 },
    "eye_light": { w: 576, h: 512 },
} as const;

export type IconsType = keyof typeof ICONS;

declare module "@uxf/ui/icon/theme" {
    interface IconsSet {
        "arrow-left": true;
        "arrow-right": true;
        "calendar": true;
        "camera": true;
        "caretDown": true;
        "clock": true;
        "bars": true;
        "check": true;
        "chevronDown": true;
        "chevronLeft": true;
        "chevronsLeft": true;
        "chevronRight": true;
        "chevronsRight": true;
        "chevronUp": true;
        "cloud": true;
        "copy": true;
        "ellipsis-vertical": true;
        "file": true;
        "imageFile": true;
        "triangle-exclamation": true;
        "user": true;
        "videoFile": true;
        "xmark": true;
        "xmarkLarge": true;
        "circle-info": true;
        "filter": true;
        "pen": true;
        "table": true;
        "file-arrow-down": true;
        "table-columns": true;
        "search": true;
        "file-download": true;
        "trash": true;
        "warning": true;
        "h1": true;
        "h2": true;
        "h3": true;
        "h4": true;
        "h5": true;
        "h6": true;
        "bold": true;
        "italic": true;
        "underline": true;
        "ol": true;
        "ul": true;
        "code-simple": true;
        "link": true;
        "image": true;
        "youtube": true;
        "block-quote": true;
        "arrow-turn-down-left": true;
        "arrow-turn-down-right": true;
        "rectangle-wide": true;
        "edit": true;
        "delete": true;
        "open-link-in-new-tab": true;
        "highlighter-line": true;
        "angleRight": true;
        "arrow-down-from-line": true;
        "arrow-up-to-line": true;
        "grip-dots-vertical": true;
        "key": true;
        "plus": true;
        "right-from-bracket": true;
        "archive": true;
        "book": true;
        "gear": true;
        "list-check": true;
        "money-bill": true;
        "money-check-dollar-pen": true;
        "plate-utensils": true;
        "qrcode": true;
        "rotate-right": true;
        "sack-dollar": true;
        "shop": true;
        "timer": true;
        "eye_light": true;
    }
}
