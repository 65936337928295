import * as Sentry from "@sentry/browser";
import { flashMessage } from "@uxf/ui/flash-messages/flash-messages-service";
import { t } from "./translation-service";

function logError(error: any): void {
    Sentry.captureException(error);
}

function getErrorMessage(error: any): string | undefined {
    if (error.message && t(error.message) !== error.message) {
        return t(error.message);
    }

    if (error.data.error.message && t(error.data.message) !== error.data.error.message) {
        return t(error.data.error.message);
    }
}

function handleError(error: any): void {
    const errorMessage = getErrorMessage(error);

    if (!errorMessage) {
        // eslint-disable-next-line no-console
        console.error(error);
    }

    flashMessage({ variant: "error", message: errorMessage ?? "Vyskytla se chyba." });
}

export const ErrorService = {
    logError,
    handleError,
};
